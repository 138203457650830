<template>
<div id="lawsDetail">
  <div class="lawsDetail-wrap">
    <h3>{{info.title}}</h3>
    <p>{{info.createTime}}<span>作者：{{info.publisherName}}</span></p>
    <div class="laws-ct" v-html="info.content"></div>
  </div>
</div>
</template>

<script>
import { getExaminationInfo } from "@/api/index.js";
export default {
  name: "lawsDetail",
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    this.getExaminationInfo()
  },
  methods: {
    getExaminationInfo() {
      getExaminationInfo({
        id: this.$route.query.id
      }).then(res => {
        this.info = res.data
        console.log(res)
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#lawsDetail {
  width: 100%;
  min-height: 200px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
}

.lawsDetail-wrap {
  width: 1000px;
  min-height: 200px;
  margin: 0 auto 30px;
  padding-left: 20px;
  padding-right: 20px;
  background: #FFFFFF;
}

h3 {
  padding-top: 39px;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  text-align: center;
}

p {
  font-size: 16px;
  font-weight: 500;
  color: #999999;
  text-align: center;
  margin-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #eee;

  span {
    display: inline-block;
    margin-left: 20px;
  }
}

.laws-ct {
  padding: 34px 30px;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  line-height: 40px;
}
</style>
<style lang="scss">
.laws-ct {
 img{
    width:935px;
    margin:20px auto;
  }
}
</style>
